<template>
  <div class="main-cont">
    <v-app-bar app color="#3A61ED" elevation="0" class="appbar"
      :class="currBP === 'sm' || currBP === 'xs' ? 'pl-0 pr-0 ' : 'pl-12 pr-12 '">
      <v-toolbar-title>
        <h1 v-if="currBP !== 'xs'" style="font-size: 18px !important">
          UAverstärker_in 2.0
        </h1>
      </v-toolbar-title>

      <v-btn variant="text" :class="currBP === 'sm' || currBP === 'xs' ? 'mr-0 pl-0' : 'mr-3'"
        @click="scrollToRef('mitmachen')">
        <h2>{{ $t("mitmachen") }}</h2>
      </v-btn>
      <v-btn variant="text" :class="currBP === 'sm' || currBP === 'xs' ? 'mr-3' : 'mr-3'" @click="scrollToRef('faq')">
        <h2>{{ $t("faq") }}</h2>
      </v-btn>
      <v-btn rounded="xl" style="background-color: #FFEA7A;" class="mr-7 donate-btn"
        v-if="currBP !== 'sm' && currBP !== 'xs'"
        @click="openLink('https://cause.lundadonate.org/vitscheev/school-bomb-shelter')">
        <h2
          style="color:#1743E1; text-transform: none; font-family: 'KyivType Sans';font-size: 20px; font-weight: 900; line-height: 24px; text-align: left;">
          {{ $t("spenden") }}</h2>
      </v-btn>
      <LocaleSwitcher />

      <v-btn v-if="currBP !== 'sm' && currBP !== 'xs'" @click="openLink('https://www.facebook.com/vitsche.berlin')"
        variant=icon size="x-small" class="mr-4">
        <v-icon>
          <img src="../assets/2/fb.svg" alt="Custom Icon">
        </v-icon>
      </v-btn>
      <v-btn v-if="currBP !== 'sm' && currBP !== 'xs'" @click="openLink('https://x.com/VitscheBerlin')" variant=icon
        size="x-small" class="mr-4">
        <v-icon>
          <img src="../assets/2/twitter.svg" alt="Custom Icon">
        </v-icon>
      </v-btn>
      <v-btn v-if="currBP !== 'sm' && currBP !== 'xs'" @click="openLink('https://www.instagram.com/vitsche_berlin/')"
        variant=icon size="x-small">
        <v-icon>
          <img src="../assets/2/insta.svg" alt="Custom Icon">
        </v-icon>
      </v-btn>


    </v-app-bar>

    <v-row justify="center">
      <div class="bus-container" v-if="currBP !== 'sm' && currBP !== 'xs'">
        <img class="bus mt-8" src="@/assets/2/mazok-2.svg" alt="bus"
          style="z-index: 9990; position: relative;pointer-events: none;">
        <div class="child" style="z-index:9997; pointer-events:none; width:48%; right:2%; top:25%">
          <h1 class="h1-special mt-5" :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">
            {{ $t('h8') }}</h1>
          <h3 style="color:white; text-transform:none" class="mt-4">
            {{ $t('h9') }} </h3>
        </div>
      </div>


    </v-row>

    <v-container>

      <v-row justify="center">
        <v-col cols="10">
          <div class="mt-10" v-if="currBP === 'sm' || currBP === 'xs'">
            <div style="display: flex; flex-direction: row;">
              <img src="@/assets/2/smol.svg" alt="">
              <h1 class="h1-special mt-5 ml-3" :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">
                {{ $t('h8') }}</h1>
            </div>
            <h3 style="color:white; text-transform:none" class="mt-4">
              {{ $t('h9') }} </h3>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" :class="currBP === 'sm' || currBP === 'xs' ? 'mt-12' : 'mt-12'">
        <v-col cols=4 sm=4 md="3" class="pa-6">
          <v-img class="logo" :src="require('../assets/Vitsche.svg')" @click="openLink('https://vitsche.org/')" />
        </v-col>
        <v-col cols=2 sm=1 md=1>
          <img class="plus-sign" :src="require('../assets/+.svg')" />
        </v-col>
        <v-col cols=4 sm=4 md="2" class="pa-0">
          <v-img class="logo" :src="require('../assets/2/prutyla.svg')"
            @click="openLink('https://prytulafoundation.org/humanitarian-help/direction/safe-and-smart')"
            style="margin-left: 0px; height: 100%" />
        </v-col>
      </v-row>

      <v-row justify="center">
        <div style="background-color: #1743E1; border-radius: 50px; padding-left:15px; padding-right: 15px;"
          class="mt-100 mb-10">
          <h1 class="h1-special pa-5 pt-2 pb-2" :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">
            {{ people.length }} {{ $t('h8') }}nen</h1>
        </div>
      </v-row>

      <v-virtual-scroll :height="currBP === 'sm' || currBP === 'xs' ? 350 : 600" :items="[1]"
        style="max-width: 100%; overflow-x: hidden;">
        <v-row justify="center">
          <v-col v-for="person in people" :key="person" cols=12 sm=6 md=4 lg=3 justify="center">
            <v-card variant="outlined" color="#FFEA7A" style="width:300px; margin: auto; border-radius: 5%;">
              <v-card-title>
                <h5 class="person-name">
                  {{ person.name }}
                </h5>
              </v-card-title>
              <hr>
              <div style="width:100%; display: flex; justify-content: end;">
                <img :src="person.imagepng" alt="person" class="person-img" style="z-index: 9;"
                  @click="openLink(person.linkLund)">
              </div>
              <hr>
              <v-card-actions justify="space-between" style="justify-content: space-between;">
                <h5 class="person-amount">
                  {{ person.amount }} €
                </h5>
                <v-btn variant="outlined" class="person-btn rounded-xl" @click="openLink(person.linkLund)">{{
        $t("spenden") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-virtual-scroll>
      <hr color="#FFEA7A">


      <div class="mt-100 mb-12" style="position: relative; overflow: hidden;" ref="infor"
        :style="currBP === 'xs' ? 'height: 20px' : 'height: 50px'">
        <img src="../assets/2/fundraising.svg" class="ml-0 moving-image" style="position:absolute; left:-15%"
          :style="currBP === 'xs' ? 'height: 20px' : 'height: 50px'" />
      </div>

      <div class="parent" :class="currBP === 'xs' ? 'mt-4' : ' mt-150'">
        <img src="../assets/2/bg-mazok.svg" alt="" style="transform: translateX(-20%);">

        <div class="child child-1" :style="currBP === 'sm' || currBP === 'xs' ? 'top: 10%;' : 'top: 0%;'">
          <v-row class="mb-3" justify="center" align="center">
            <v-col cols=3 sm=3 :class="currBP === 'xs' ? 'pa-0' : ''">
              <img src="../assets/2/house.svg" alt="house" style="width:100%" class="rotating-image">
            </v-col>
            <v-col cols=12 sm=8 md=7 align-self="end">
              <h2 class="mb-5" style="color:white; font-weight: 900;"
                :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">
                {{ $t("h1") }}
              </h2>
              <h3 style="color:white">
                {{ $t('h2') }}</h3>
            </v-col>
          </v-row>

        </div>
      </div>
      <!-- 1 2 3 -->
      <v-row :justify="currBP === 'sm' || currBP === 'xs' ? 'center' : 'end'" class="mt-100">
        <v-col cols="11" md="8">
          <div style="display: flex; flex-direction: row; align-items: center">
            <img v-if="currBP === 'sm' || currBP === 'xs'" src="../assets/2/sp1_1.svg" width="50" class="mr-4" alt="">
            <h1 class="h1-special" :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">{{
        $t("h3").toUpperCase() }}
            </h1>
          </div>
          <h3 style="color:white; text-transform:none" class="mt-4">
            {{ $t('h4') }}
          </h3>
          <h3 style="color:white; text-transform:none" class="mt-4">
            {{ $t('h42') }}
          </h3>
        </v-col>
        <v-col v-if="currBP !== 'sm' && currBP !== 'xs'" cols=2 md=3
          style="display: flex; justify-content: right; align-items: self-start;">
          <img src="../assets/2/sp1.svg" alt="" class="rotating-image"
            :style="currBP === 'sm' || currBP === 'xs' ? 'width:250%' : 'width:150%'">
        </v-col>
      </v-row>

      <v-row :justify="currBP === 'sm' || currBP === 'xs' ? 'center' : 'space-between'" class="mt-100">
        <v-col v-if="currBP !== 'sm' && currBP !== 'xs'" cols=2 md=3
          style="display: flex; justify-content: left; align-items: self-start;">
          <img src="../assets/2/sp2.svg" alt="" class="rotating-image"
            :style="currBP === 'sm' || currBP === 'xs' ? 'width:200%; transform: translateY(-20px)' : 'width:150%'">
        </v-col>
        <v-col cols="11" md=7>
          <div style="display: flex; flex-direction: row; align-items: center">
            <img v-if="currBP === 'sm' || currBP === 'xs'" src="../assets/2/sp2_1.svg" width="50" class="mr-4" alt="">
            <h1 class="h1-special" :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">{{
        $t('h41').toUpperCase() }}
            </h1>
          </div>


          <h3 style="color:white; text-transform:none" class="mt-4">
            {{ $t('h5') }}
          </h3>
        </v-col>
      </v-row>

      <v-row :justify="currBP === 'sm' || currBP === 'xs' ? 'center' : 'end'" class="mt-100">
        <v-col cols="11" md=7>
          <div style="display: flex; flex-direction: row; align-items: center">
            <img v-if="currBP === 'sm' || currBP === 'xs'" src="../assets/2/sp3_1.svg" width="50" class="mr-4" alt="">
            <h1 class="h1-special" :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">
              {{ $t('h6').toUpperCase() }}</h1>
          </div>


          <h3 style="color:white; text-transform:none" class="mt-4">
            {{ $t('h7') }} </h3>
        </v-col>
        <v-col v-if="currBP !== 'sm' && currBP !== 'xs'" cols=2 md=3
          style="display: flex; justify-content: right; align-items: self-start;">
          <img src="../assets/2/sp3.svg" alt="" class="rotating-image"
            :style="currBP === 'sm' || currBP === 'xs' ? 'width:250%' : 'width:150%'">
        </v-col>
      </v-row>

      <div class="mt-100 mb-12" style="position: relative; overflow: hidden;"
        :style="currBP === 'xs' ? 'height: 60px' : 'height: 80px'">
        <img src="../assets/2/line2.svg" class="ml-0 moving-image" style="position:absolute; left:0"
          :style="currBP === 'xs' ? 'height: 60px' : 'height: 80px'" />
      </div>



      <v-btn rounded="xl" style="background-color: #FFEA7A;" class="mr-2 donate-btn absol"
        v-if="currBP === 'sm' || currBP === 'xs'"
        @click="openLink('https://cause.lundadonate.org/vitscheev/school-bomb-shelter')">
        <h2 style="color:#1743E1">{{ $t("spenden") }}</h2>
      </v-btn>

      <v-row justify="center">
        <v-col cols=12 md=10>
          <h2 class="mb-5" style="color:#FFEA7A; font-weight: 900; text-align: center;"
            :class="currBP === 'sm' || currBP === 'xs' ? 'width-100-percent' : ''">
            {{ $t("kids") }}
          </h2>
          <h3 style="color:white; text-transform:none; text-align: center;" class="mt-4 pl-4 pr-4">
            {{ $t("kids2") }}
          </h3>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols=10 sm=3 align="center" style="margin-bottom: -100px;">
          <video preload class="vert_video" width="320" height="240" controls poster="../assets/v1.png">
            <source src="../assets/v1.mp4" type="video/mp4">
          </video>
        </v-col>
        <v-col cols=10 sm=3 align="center" style="margin-bottom: -100px;">
          <video preload class="vert_video" width="320" height="240" controls poster="../assets/v2.png">
            <source src="../assets/v2.mp4" type="video/mp4">
          </video>
        </v-col>
        <v-col cols=10 sm=3 align="center" style="margin-bottom: -100px;">
          <video preload class="vert_video" width="320" height="240" controls poster="../assets/v3.png">
            <source src="../assets/v3.mp4" type="video/mp4">
          </video>
        </v-col>
      </v-row>

      <!-- <ShelterPlan/> -->

      <v-row justify="center">
        <v-col cols="12">

          <h1 class="ma-auto mt-150 mb-6" ref="faq">FAQ</h1>
        </v-col>
        <v-col cols=12 sm=11 md=11 lg=8 xl=8>
          <v-expansion-panels variant="default" v-model="panel">
            <v-expansion-panel v-for="q in faqs[$i18n.locale] " :key="q">
              <v-expansion-panel-title>
                <h4>
                  {{ q.title }}
                </h4>
                <template v-slot:actions="{ expanded }">
                  <img v-if="expanded" class="img-expansion expanded" :src="require('../assets/Up.svg')" alt="">
                  <img v-else class="img-expansion " :src="require('../assets/Down.svg')" alt="">
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <h5>
                  {{ q.text }}
                </h5>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>


    </v-container>

    <v-footer color="#3A61ED" class="pt-10 pb-10">
      <v-row justify="space-between">
        <v-col cols=12 sm="5">
          <v-row justify="start" align="center">
            <v-col cols=4 sm=4 md="3" class="pa-6 pl-3">
              <v-img class="logo" :src="require('../assets/Vitsche.svg')" @click="openLink('https://vitsche.org/')" />
            </v-col>

            <v-col cols=4 sm=4 md="2" class="pa-0">
              <v-img class="logo" :src="require('../assets/2/prutyla.svg')"
                @click="openLink('https://prytulafoundation.org/humanitarian-help/direction/safe-and-smart')"
                style="margin-left: 0px; height: 100%" />
            </v-col>
          </v-row>
          <h5 class="become-amp mb-3" ref="mitmachen"> {{ $t('h15') }}</h5>
          <h5 class="become-amp-txt"> {{ $t('h12') }}</h5>
          <h5 class="become-amp-txt">ukraineamplifiers@vitsche.org</h5>

          <v-btn rounded="xl" style="background-color: #FFEA7A; box-shadow: none;" class="mt-6"
            @click="openLink('https://forms.gle/rSj4jgtkghRFzHiHA')">
            <h2
              style="color:#1743E1; text-transform: none; font-family: 'KyivType Sans';font-size: 20px; font-weight: 900; line-height: 24px; text-align: left;">
              {{ $t("h14") }}
            </h2>
          </v-btn>
        </v-col>

        <v-col cols=12 sm="3" class="t-0">
          <v-btn variant="text" :class="currBP === 'sm' || currBP === 'xs' ? 'mr-0 pl-0' : 'pl-1 mr-3'"
            @click="scrollToRef('infor')">
            <h2 style="color: white">{{ $t("info") }}</h2>
          </v-btn>
          <v-btn variant="text" :class="currBP === 'sm' || currBP === 'xs' ? 'mr-3 pl-0' : 'pl-1 mr-3'"
            @click="scrollToRef('faq')">
            <h2 style="color: white">{{ $t("faq") }}</h2>
          </v-btn>

          <div class="mt-2">
            <v-btn @click="openLink('https://www.facebook.com/vitsche.berlin')" variant=icon size="x-small"
              class="mr-4 pl-0">
              <v-icon>
                <img src="../assets/2/fb.svg" alt="Custom Icon">
              </v-icon>
            </v-btn>
            <v-btn @click="openLink('https://x.com/VitscheBerlin')" variant=icon size="x-small" class="mr-4 pl-0">
              <v-icon>
                <img src="../assets/2/twitter.svg" alt="Custom Icon">
              </v-icon>
            </v-btn>
            <v-btn @click="openLink('https://www.instagram.com/vitsche_berlin/')" variant="icon" size="x-small"
              class="pl-0">
              <v-icon>
                <img src="../assets/2/insta.svg" alt="Custom Icon">
              </v-icon>
            </v-btn>
          </div>

          <div class="mt-2">
            <v-btn variant="outlined" rounded="xl" class="mt-2"
              @click="openLink('https://cause.lundadonate.org/vitscheev/school-bomb-shelter')">
              <h2
                style="color:#FFEA7A; text-transform: none; font-family: 'KyivType Sans';font-size: 20px; font-weight: 600; line-height: 24px; text-align: left;">
                {{ $t("spenden") }}</h2>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import LocaleSwitcher from "../components/LocaleSwitcher.vue"
import { useI18n } from "vue-i18n";

export default {
  name: 'BombshelterSchool',
  components: { LocaleSwitcher },
  props: {
    msg: String
  },
  mounted() {
    let lang = localStorage.getItem("lang")
    if (lang) {
      this.$i18n.locale = lang
    }
  },
  data() {
    return {
      indexes: [14, 13, 12, 11, 10, null, 9, 8, 7, 6, 5, null, 4, 3, 2, 1, 0, null],
      hoveredItem: null,
      t: useI18n(),
      panel: [],
      people: [
        // {
        //     name: "Fellas for Europe e.V.",
        //     collected: false,
        //     amount: 1000,
        //     linkLund: "link",
        //     imagepng: require('@/assets/2/amplifiers/o.png'),
        // },

        // {
        //     name: "Fabian Baumann",
        //     collected: false,
        //     amount: 1000,
        //     linkLund: "link",
        //     imagepng: require('@/assets/2/amplifiers/o.png'),
        // },
        {
          name: "Frank Wilde",
          collected: false,
          amount: 1500,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-frankw",
          imagepng: require('@/assets/2/amplifiers/frank.png'),
        },
        {
          name: "Robin Wagener",
          collected: false,
          amount: 3000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-robinw",
          imagepng: require('@/assets/2/amplifiers/robin.png'),
        },
        {
          name: "Ewa Herzog",
          collected: false,
          amount: 5000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-ewah",
          imagepng: require('@/assets/2/amplifiers/eva.png'),
        },
        {
          name: "Zlata Koltsova",
          collected: false,
          amount: 250,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-zlatak",
          imagepng: require('@/assets/2/amplifiers/zlata.png'),
        },
        {
          name: "Philippe Schockweiler",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-philippes",
          imagepng: require('@/assets/2/amplifiers/phillipe.png'),
        },
        {
          name: "Botakoz Kassymbekova",
          collected: false,
          amount: 5000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-botakozk",
          imagepng: require('@/assets/2/amplifiers/bota.png'),
        },
        {
          name: "Iryna Shylikina",
          collected: false,
          amount: 3000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-irynas",
          imagepng: require('@/assets/2/amplifiers/ira.png'),
        },
        {
          name: "Pavlo Plastikk",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-pavlop",
          imagepng: require('@/assets/2/amplifiers/pavlop.png'),
        },
        {
          name: "SonyashnyKiel & Rozmova e.V.",
          collected: false,
          amount: 8000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-sonyashnykiel",
          imagepng: require('@/assets/2/amplifiers/rozmova.png'),
        },
        {
          name: "Simon Muschick",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-simonm",
          imagepng: require('@/assets/2/amplifiers/simon.png'),
        },
        {
          name: "Olha Sirko",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-olhas",
          imagepng: require('@/assets/2/amplifiers/olha.png'),
        },
        {
          name: "Tatyana Sysak",
          collected: false,
          amount: 150,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-tatyanas",
          imagepng: require('@/assets/2/amplifiers/sys.png'),
        },
        {
          name: "Yuliia Leshchuk",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-yuliial",
          imagepng: require('@/assets/2/amplifiers/leshch.png'),
        }, {
          name: "Liza Aikin",
          collected: false,
          amount: 300,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-lizaa",
          imagepng: require('@/assets/2/amplifiers/lizaai.png'),
        },
        {
          name: "Sofia Chybisova",
          collected: false,
          amount: 250,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-sofiac",
          imagepng: require('@/assets/2/amplifiers/sofi.png'),
        },
        {
          name: "Olga Rozhankovska",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-olgar",
          imagepng: require('@/assets/2/amplifiers/olgar.png'),
        },
        {
          name: "XJAZZ",
          collected: false,
          amount: 500,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-xjazz",
          imagepng: require('@/assets/2/amplifiers/xjazz.png'),
        },

        {
          name: "Frankziska Davies",
          collected: false,
          amount: 5000,
          text: "Ich bin Ukraineverstärkerin",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-franziskad",
          imagepng: require('@/assets/2/amplifiers/1.png'),
        },

        {
          name: "Vitaliy Rizenko",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-vitaliyr",
          imagepng: require('@/assets/2/amplifiers/vitali.png'),
        },

        {
          name: "Alla Bahlei",
          collected: false,
          amount: 500,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-allab",
          imagepng: require('@/assets/2/amplifiers/alleb.png'),
        },

        {
          name: "Patrik Heinemann",
          collected: false,
          amount: 10000,
          showTooltip: false,
          text: "Ich bin Ukraineverstärker",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-patrickh",
          imagepng: require('@/assets/2/amplifiers/2.png'),

        },
        {
          name: "Rebecca Harms",
          collected: false,
          amount: 5000,
          showTooltip: false,
          text: "Ich bin Ukraineverstärkerin",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-rebeccah",
          imagepng: require('@/assets/2/amplifiers/3.png'),

        },

        {
          name: "Vladyslava Vorobiova",
          collected: false,
          amount: 1500,
          showTooltip: false,
          text: "Ich bin Ukraineverstärkerin",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-vladav",
          imagepng: require('@/assets/2/amplifiers/5.png'),

        },
        {
          name: "Till Mayer",
          collected: false,
          amount: 3000,
          showTooltip: false,
          text: "Ich bin Ukraineverstärker",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-tillm",
          imagepng: require('@/assets/2/amplifiers/6.png'),

        },
        {
          name: "Eva Yakubovska",
          collected: false,
          amount: 1500,
          showTooltip: false,
          text: "Ich bin Ukraineverstärkerin",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-evay",
          imagepng: require('@/assets/2/amplifiers/7.png'),

        },

        {
          name: "Ukrainian Sound Garden",
          collected: false,
          amount: 8000,
          showTooltip: false,
          text: "Wir sind Ukraineverstärkerinnnen",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-usg6",
          imagepng: require('@/assets/2/amplifiers/usg.png'),
        },

        {
          name: "Chris Berger",
          collected: false,
          amount: 2500,
          showTooltip: false,
          text: "Ich bin Ukraineverstärker",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-chrisb",
          imagepng: require('@/assets/2/amplifiers/chris.png'),
        },

        {
          name: "Alexandra Braun",
          collected: false,
          amount: 10000,
          showTooltip: false,
          text: "Ich bin Ukraineverstärkerin",
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-alexandrab",
          imagepng: require('@/assets/2/amplifiers/alexbraun.png'),
        },

        {
          name: "With Ukraine",
          collected: false,
          amount: 3000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-withukraine",
          imagepng: require('@/assets/2/amplifiers/withu.png'),
        },

        {
          name: "Irina Bystritskaya",
          collected: false,
          amount: 300,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-irinab",
          imagepng: require('@/assets/2/amplifiers/irinab.png'),
        },

        {
          name: "Jean Dupouy",
          collected: false,
          amount: 1500,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-jeand",
          imagepng: require('@/assets/2/amplifiers/jean.png'),
        },

        {
          name: "Christian Hagemann",
          collected: false,
          amount: 2000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-chrish",
          imagepng: require('@/assets/2/amplifiers/hagem.png'),
        },

        {
          name: "Vechornytsi",
          collected: false,
          amount: 2000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-vechornytsi-berlin",
          imagepng: require('@/assets/2/amplifiers/vechir.png'),
        },
        {
          name: "Nataliia Fiebrig",
          collected: false,
          amount: 5000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-nataliiaf",
          imagepng: require('@/assets/2/amplifiers/nfieb.png'),
        },

        {
          name: "Maria Valtchuk",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-mariav",
          imagepng: require('@/assets/2/amplifiers/mar.png'),
        },

        {
          name: "IT Zdybanka",
          collected: false,
          amount: 1000,
          linkLund: "https://cause.lundadonate.org/vitscheev/school-bomb-shelter-itzdybanka",
          imagepng: require('@/assets/2/amplifiers/it.png'),
        },
      ],

      faqs: {
        "de": [
          {
            title: 'Was bedeutet es, eine #UkraineVerstärker_in zu sein?',
            text: "Als Ukraineverstärker*innen stärken diese Menschen die Stimmen der Ukrainer*innen und unterstützen sie im Ausland, um aus der Ferne einen Unterschied zu machen und Leben zu retten. “UkraineVerstärker_in” steht im Gegensatz zum Begriff 'Russlandversteher'."
          },
          {
            title: 'Wie funktioniert diese einzigartige kollektive Spendenkampagne?',
            text: "Vitsche hat eine große Spendenseite mit einem Ziel von 200.000 €. Dieses Ziel wird unter Ukraineverstärker*innen in kleinere Beträge mit separaten Spendenseiten aufgeteilt. Sobald jede Seite ihr Ziel erreicht hat, werden die Gelder auf die Hauptspendenseite übertragen, sodass insgesamt 200.000 € in der Hauptspendenkampagne gesammelt werden."
          },
          {
            title: 'Wie wirkt sich meine Spende auf die Steuern aus?',
            text: "Für Spenden bis zu 300 Euro können Steuergutschriften mit einer vereinfachten Spendenquittung beantragt werden. Spenden über 300 Euro sind steuerlich absetzbar, die entsprechenden Unterlagen werden von Vitsche zur Verfügung gestellt. Um einen Spendennachweis anzufordern, geben Sie Ihre E-Mail-Adresse, Ihren vollständigen Namen und die Adresse des Spenders an. Für größere Spenden oder Firmenspenden kontaktieren Sie uns bitte unter invoices@vitsche.org."
          },
          {
            title: "Warum ist diese Schule besonders wichtig?",
            text: "Die Schule in Nowopokrowka ist die wichtigste Schule für eine gesamte Gemeinde. Sie ist die einzige Schule und nahezu der einzige Schutzraum für fünf benachbarte Dörfer. Kinder aus kleineren Orten des Landkreises kommen dorthin zum Unterricht. Insgesamt besuchen 397 Kinder diese Schule. Aufgrund des fehlenden Schutzraums, der vor Angriffen und Raketen schützt, müssen sie nun seit über 2 Jahren online lernen. Ein renovierter Schutzraum würde es ihnen ermöglichen, endlich wieder in die Schule zu gehen und dort sicher zu sein. Darüber hinaus kann der Schutzraum während der Bombardierungen nicht nur Kinder, sondern auch Einwohner der gesamten Dorfbevölkerung schützen."
          },
          {
            title: "Ist es angesichts der aktuellen Frontlage sicher und sinnvoll, dort einen Luftschutzraum zu errichten?",
            text: "Dies ist die Heimat der Menschen - sie haben dort Arbeit, Geschäfte und Familien, und wir können sie dort nicht ohne Unterstützung zurücklassen. Die Menschen wollen ihre Häuser nicht verlassen, und viele haben auch keine Möglichkeit dazu. Wir möchten die lokale Gemeinschaft stärken und ihr Leben sicherer und lebenswerter machen, damit sie nicht gezwungen sind, ihre Häuser zu verlassen und im Ausland Zuflucht zu suchen. Selbst wenn wir den Schutzraum nicht renovieren, werden ganze Familien in Nowopokrowka und den umliegenden Dörfern bleiben. Das bedeutet, dass die Gefahr für das Leben der Kinder und der örtlichen Bevölkerung, die sich vor dem russischen Beschuss nirgendwo verstecken können, weiterhin bestehen bleibt. Mit einem neuen Schutzraum können wir diese Lebensgefahr verringern und den Kindern eine Möglichkeit geben, ihre Kindheit in Sicherheit und unter Altersgenossen zu verbringen. Wir haben Vertrauen in die ukrainischen Streitkräfte und sehen, dass die Region Charkiw sicherer und gefestigter wird."
          },
          {
            title: "Warum ist die Renovierung des Schulschutzraums so teuer?",
            text: "Im Wesentlichen handelt es sich um einen Neubau, da der aktuelle Zustand des Schutzraums nicht alle Sicherheitsanforderungen erfüllt. Bei der Renovierung dieses Schutzraums geht es nicht nur ums Streichen der Wände. Dazu wird ein Gutachten des staatlichen Rettungsdienstes (SES) durchgeführt und eine Lizenz für einen Schutzraum erworben, der alle Sicherheitsmerkmale erfüllt. Außerdem handelt es sich nicht nur um einen einfachen Schutzraum, sondern um voll funktionsfähige unterirdische Mehrzweckräume, die einen komfortablen Aufenthalt für 400 Personen ermöglichen. Der Schutzraum wird mit Strahlenschutzrampen, Bereichen für Kinder mit Autismus-Spektrum-Störung, Schlafplätzen, Lagerräumen für Lebensmittel und Trinkwasser, Belüftung usw. ausgestattet. Alles, was im Schutzraum erneuert und eingerichtet wird, soll einen autonomen und sicheren Aufenthalt für 3 volle Tage ermöglichen."
          },
          {
            title: "Sind Kinder überhaupt während des Krieges bereit, die Schule zu besuchen?",
            text: "Ja, die Kinder sind bereit und möchten gerne die Schule besuchen. Sie vermissen den Präsenzunterricht, den Austausch mit Gleichaltrigen und Lehrern sowie verschiedenen sozialen Aktivitäten."
          }

        ],
        "en": [
          {
            title: 'How does this unique collective fundraising campaign work?',
            text: "Vitsche has a donation page with a goal of €200,000. This goal is divided among Ukraineverstärkers amplifiers into smaller amounts, each with its own donation page. Once each page has reached its goal, the funds are transferred to the main donation page so that a total of €200,000 is collected in the primary fundraising campaign."
          },
          {
            title: "Why is this school particularly important?",
            text: "The school in Novopokrovka is the main school for an entire community. It is the only school and almost the only shelter for five surrounding villages. Children from smaller villages in the district come to study there. In total, 397 children study at this school. Due to the lack of a safe shelter, they were forced to study online for more than two years. A renovated shelter will allow them to finally go to school and be safe there. Moreover, during the bombings, the shelter can protect not only children but also the population of the village."
          },
          {
            title: 'How does my donation affect my taxes?',
            text: "Tax credits can be requested with a simplified donation receipt for donations up to 300 euros. Donations over 300 euros are tax-deductible, and Vitsche provides the corresponding documents. To request a donation receipt, please enter your email address, full name, and address. For more significant donations or corporate donations, please contact us at invoices@vitsche.org."
          },
          {
            title: "Considering the current situation on the frontlines, would it be safe and sensible to build a bomb shelter there?",
            text: "This is the home of people; people have jobs, businesses, and families there, and we cannot leave people there without help. People do not want to leave their homes, and some cannot. We, in turn, want to support the viability of the local community, make their lives safer and brighter so that they do not have to leave their homes and seek refuge abroad. Even if we do not renovate the shelter, entire families will not leave Novopokrovka and nearby villages. That is, the risks to the lives of children and the local population who have nowhere to hide from russian shelling will still be there. With a new shelter, we will reduce the risks to their lives and give children the opportunity to have a childhood surrounded by their peers and in safety. \r\r We also believe in the Armed Forces of Ukraine and see that the fortification of the Kharkiv region continues."
          },
          {
            title: "Why is the renovation of the school shelter so expensive?",
            text: "In essence, this is construction since the shelter's current state is unsuitable for all safety requirements. Renovating this shelter is not about painting the walls. For this, SES (State Emergency Service of Ukraine) expertise is carried out, and a license is obtained for a shelter that will meet all safety characteristics. In addition, it is not just a shelter - it is a full-fledged multifunctional underground premises that will make it possible for 400 people to stay there comfortably. The shelter will be equipped with anti-radiation ramps, corners for children with autism spectrum, sleeping places, storage rooms for food and drinking water, ventilation, and so on. Everything that will be updated and created in the shelter will be able to ensure an autonomous and safe stay there for 3 full days."
          },
          {
            title: "Are children ready to attend school during the war?",
            text: "Yes, children are ready and want to attend school. They miss in-person learning, communication with peers and teachers, and various social activities."
          }
        ]
      }
    }
  },
  computed: {
    currBP() {
      if (this.$vuetify.display['sm']) {
        return 'sm'
      } else if (this.$vuetify.display['xs']) {
        return 'xs'
      } else if (this.$vuetify.display['md']) {
        return 'md'
      } else if (this.$vuetify.display['lg']) {
        return 'lg'
      } else {
        return 'def'
      }
    }
  },
  methods: {
    // onLoad(frame) {
    //   console.log('iframe lonload')
    //   this.myIframe = frame.contentWindow
    // },
    scrollToRef(elem) {
      const element = this.$refs[elem];
      console.log(element, this.$refs)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    openLink(link) {
      //'https://cause.lundadonate.org/vitscheev/medicalbusfundraiserfrankwilde'
      window.open(link, '_blank');
    }
  }
}
</script>

<style scoped>
.main-cont {

  width: 100vw;
  overflow: hidden !important
}

.width-100-percent {
  font-size: 100% !important;
}

.mazok {
  background-image: url("../assets/2/bg-mazok.svg") !important;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: left;
  padding: 30px;
  height: 100%;
  border: none;

}

.appbar {
  z-index: 9999;
  overflow: visible !important
}

.v-toolbar__content {
  overflow: visible !important
}

.appbar h1 {
  font-family: "KyivType Sans";
  font-size: 25px;
  font-weight: 700;
  line-height: 38.5px;
  text-align: left;
  color: white
}

.appbar h2 {
  font-family: "KyivType Sans";
  font-size: 17px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  color: white
}

.v-container {
  max-width: 1400px;
}

.child {
  position: absolute;
  top: 100px;
}

.child-1 {
  left: 0;
  height: 100%;
  width: 100%;
}

.moving-image {
  position: absolute;
  left: -15%;
  animation: moveImage 50s linear infinite;
}

@keyframes moveImage {
  0% {
    left: -15%;
  }

  50% {
    left: -50%;
  }

  100% {
    left: -15%;
  }
}

.rotating-image {
  display: block;
  margin: 0 auto;
  animation: rotateImage 1s infinite alternate;
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(5deg);
    /* Rotate 5 degrees clockwise */
  }

  100% {
    transform: rotate(0deg);
  }
}

.parent {
  position: relative;
  overflow: visible;
}

@font-face {
  font-family: "KyivType Sans";
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("../assets/fonts/KyivTypeSans/KyivTypeSans-Medium.ttf");
}

@font-face {
  font-family: "KyivType Sans";
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("../assets/fonts/KyivTypeSans/KyivTypeSans-Bold.ttf");
}

@font-face {
  font-family: "KyivType Sans";
  font-weight: 800;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("../assets/fonts/KyivTypeSans/KyivTypeSans-Heavy.ttf");
}

h6 {
  font-family: "KyivType Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: white
}

.logo {
  cursor: pointer;
  height: 50px;

}

.plus-sign {
  height: 20px;
}

.bus-container {
  position: relative;
  width: 100vw !important;
  min-width: 900px !important;
  max-width: 1766px !important;
}

@media (max-width: 900px) {

  .appbar {
    z-index: 9999;
  }

  .appbar h1 {
    font-family: "KyivType Sans";
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    text-align: left;
    color: white
  }

  .appbar h2 {
    font-family: "KyivType Sans";
    font-size: 12px !important;
    font-weight: 900 !important;
    line-height: 18px !important;
    text-align: left;
    color: white
  }



  h6 {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  h1 {
    font-size: 30px !important;
    font-weight: 800;
    line-height: 45px !important;
  }

  .h1-special {
    line-height: 20px !important;

  }

  h2 {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  h3 {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  h5 {
    font-size: 14px !important;
    line-height: 18px !important;
  }

}

.bus {
  width: 100%;
  height: auto;
}

.person {
  position: absolute;
  align-content: end;
  height: 200px;
  width: 150px;
}

.person-img {
  cursor: pointer;
  height: 200px;
  max-width: 100%;
  align-self: right
}

.tooltip-amp {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(80px, -75px);
  z-index: 9998;
  width: 250px;
  height: 155px;
}

.tooltip-amp-mobile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 0%;
  z-index: 9;
  width: 240px;
  height: 155px;
}

.tooltip-amp-child {
  position: absolute;
  z-index: 9990;
}

.bg-img {
  width: 250px;
  /* Adjust width as needed */
  height: auto;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  top: 0%;
  left: 0%;
  padding: 15px 25px 15px 25px
}

.tooltip-content h1 {
  font-family: "KyivType Sans";
  font-size: 16px !important;
  font-weight: 700;
  line-height: 22px !important;
  text-align: left;
  color: #1743E1;
}

.tooltip-content h2 {
  font-family: "KyivType Sans";
  font-size: 10px !important;
  font-weight: 800;
  line-height: 11px !important;
  text-align: right;
  color: #1743E1;

}

p {
  font-family: "KyivType Sans";
  font-size: 10px;
  font-weight: 800;
  line-height: 11px;
  color: #1743E1;
}

h1 {
  font-family: "KyivType Sans";
  font-size: 40px !important;
  font-weight: 800 !important;
  line-height: 60px !important;
  text-align: center;
  color: white
}

.h1-special {
  color: #FFEA7A;
  text-align: left;
  font-family: "KyivType Sans";
  font-size: 30px;
  font-weight: 800;
  line-height: 60px;
}

h2 {
  font-family: "KyivType Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #3232F9;
  text-transform: uppercase
}

h3 {
  font-family: "KyivType Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  text-align: left;
  color: #3232F9
}

h4 {
  font-family: "KyivType Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #3232F9
}

h5 {
  font-family: "KyivType Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #3232F9
}

.v-expansion-panel-title__icon {
  width: 24px;
  height: 24px
}

.img-expansion {
  display: block;
  width: 24px;
}

.wrapper {
  position: relative
}



h5 {
  font-family: "KyivType Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;

}

.mt-250 {
  margin-top: 250px !important
}

.mt-200 {
  margin-top: 200px !important
}

.mt-150 {
  margin-top: 150px !important
}

.mt-100 {
  margin-top: 100px !important
}

.mt-30 {
  margin-top: 30px !important
}

.slider-amp {
  width: 100vw;
  margin: auto;
  padding: auto;
  background-color: transparent;
  height: 400px !important;
}

.img-next,
.img-prev {
  display: block;
  width: 12px;
  z-index: 9997;
}

.donate-btn {

  animation: pulse 2s infinite;

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.absol {
  position: fixed !important;
  bottom: 10px;
  right: 0px;
}

.person-name {
  font-family: "KyivType Sans";
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color: #FFEA7A
}

.person-amount {
  font-family: "KyivType Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  color: #FFEA7A
}

.person-btn {
  font-family: "KyivType Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #FFEA7A
}

.become-amp {
  font-family: "KyivType Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
  color: #FFEA7A
}

.become-amp-txt {
  font-family: "KyivType Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
  color: #FFFFFF
}

/* this query will apply for the devices those have width smaller than 480px */
@media (max-width: 480px) {
  .vert_video {
    width: 100%;
    height: 100%;
  }
}

.vert_video {
  width: 75%;
  height: 75%;
}
</style>
